//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from "@/components/Pagination";
import { fetchReportList, fetchCompanyList } from "../../../api/partner";
export default {
  name: "PartnerReportList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        filter: {
          start_time: "",
          end_time: "",
          company_id: ""
        }
      },
      bill_date: "",
      // 表格数据
      list: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      companyLoading: false,
      companyOptions: []
    };
  },
  created: function created() {
    this.fetchList();
  },
  methods: {
    fetchList: function fetchList() {
      var _this = this;

      this.tableLoading = true;
      fetchReportList(this.searchForm).then(function (response) {
        _this.list = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.fetchList();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      this.searchForm.page = val;
      this.fetchList();
    },
    handlePageSize: function handlePageSize(val) {
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.fetchList();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.searchForm.filter["start_time"] = val[0];
        this.searchForm.filter["end_time"] = val[1];
      } else {
        this.searchForm.filter["start_time"] = "";
        this.searchForm.filter["end_time"] = "";
      }
    },
    remoteCompany: function remoteCompany(query) {
      var _this2 = this;

      if (query !== "") {
        this.companyLoading = true;
        setTimeout(function () {
          _this2.companyLoading = false;
          var data = {
            keyword: query
          };
          fetchCompanyList(data).then(function (res) {
            _this2.companyOptions = res.data;
          });
        }, 100);
      } else {
        this.companyOptions = [];
      }
    }
  }
};
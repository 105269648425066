var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "form-container" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search_form",
              attrs: { model: _vm.searchForm, inline: "" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司:", prop: "company_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入公司搜索",
                        "remote-method": _vm.remoteCompany,
                        loading: _vm.companyLoading,
                        clearable: ""
                      },
                      model: {
                        value: _vm.searchForm.filter.company_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm.filter, "company_id", $$v)
                        },
                        expression: "searchForm.filter.company_id"
                      }
                    },
                    _vm._l(_vm.companyOptions, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "日期:", prop: "bill_date" } },
                [
                  _c("el-date-picker", {
                    staticClass: "date-picker_width",
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      "unlink-panels": true,
                      placeholder: "选择日期"
                    },
                    on: { change: _vm.handleTime },
                    model: {
                      value: _vm.bill_date,
                      callback: function($$v) {
                        _vm.bill_date = $$v
                      },
                      expression: "bill_date"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "table-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              attrs: { data: _vm.list, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "bill_date",
                  label: "日期",
                  "header-align": "center",
                  align: "center",
                  width: "120px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_amount",
                  label: "订单金额",
                  "header-align": "center",
                  align: "center",
                  width: "180px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_num",
                  label: "订单数量",
                  "header-align": "center",
                  align: "center",
                  width: "180px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_refund",
                  label: "订单退款金额",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "order_refund_num",
                  label: "订单退款数量",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "user_num",
                  label: "今日新增用户",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "user_recharge_amount",
                  label: "今日充值金额",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "total_electricity_charge",
                  label: "总电费",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "total_service_charge",
                  label: "总服务费",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "total_energy",
                  label: "电量",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-left": "10px",
                              "text-align": "left"
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "总电量：" + _vm._s(scope.row.total_energy)
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "平时段电量：" + _vm._s(scope.row.total_flat)
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "高峰时段电量：" + _vm._s(scope.row.total_peak)
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "尖峰时段电量：" + _vm._s(scope.row.total_point)
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "低谷时段电量：" +
                                  _vm._s(scope.row.total_valley)
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  width: "130px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          { attrs: { type: "text", size: "small" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  tag: "span",
                                  to: {
                                    path: "/agent/report/property",
                                    query: {
                                      bill_date: scope.row.bill_date
                                    }
                                  }
                                }
                              },
                              [_vm._v("小区日报")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.totalPage > 0,
            expression: "totalPage > 0"
          }
        ],
        attrs: {
          total: _vm.totalPage,
          page: _vm.searchForm.page,
          limit: _vm.searchForm.per_page
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchForm, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchForm, "per_page", $event)
          },
          pagination: _vm.fetchList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }